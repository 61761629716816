import { useProfiles } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useOrganization,
} from '@guider-global/sanity-hooks';
import { Avatar, Header, ScrollToTop, theme } from '@guider-global/ui';
import { Box, Stack, ThemeProvider, useTheme } from '@mui/system';
import { LanguageMenu } from 'components';

import { useLocalization } from 'hooks';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { hideNavbar, showNavbar } from 'store/slices/appSlice';
import { deepMerge } from 'utils';

interface ProgressContainerProps {
  children: ReactNode;
  onBackButtonClick?: VoidFunction;
  endAdornment?: ReactNode;
  percentage?: number;
}

export function ProgressContainer({
  children,
  onBackButtonClick,
  endAdornment,
  percentage,
}: ProgressContainerProps) {
  const [progress, setProgress] = useState<number | undefined>();

  // Hooks

  const organizationSlug = getSubDomain();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const { localeCode, handleLanguageChange } =
    useLocalization(organizationSlug);

  // Hide navbar
  useEffect(() => {
    dispatch(hideNavbar(true));
    return () => {
      dispatch(showNavbar(true));
    };
  }, [dispatch]);

  // Theme

  const organizationTheme = useTheme();
  const palette = deepMerge(theme.appTheme.palette, organizationTheme.palette);

  const { organization } = useOrganization({
    organizationSlug,
  });

  const { profile } = useProfiles({ params: { integrationsEnabled: true } });

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    if (!profile) return;

    /// Stages for progress:
    // If mentor only: Participation (20%), Goals (40%), Skills (60%), Bio (80%)
    // If mentee only: Participation (20%), Goals (40%), Skills (60%), Bio (80%), Matching  (100%)
    // If both: Participation (20%), Goals (33%), Skills 1 (50%), Skills 2 (66%), Bio (83%), Matching (100%)

    // The bio screen doesnt show the bar as its used for both open matching and regular registration
    if (pathname.includes('bio')) {
      setProgress(undefined);
      return;
    }

    // The participation screen is fixed at 20% as we dont know their role yet
    if (pathname.includes('participation')) {
      setProgress(20);
      return;
    }

    if (!profile.roles) return;

    // Set progress for profiles with both roles
    if (profile.roles.includes('trainee') && profile.roles.includes('guide')) {
      if (pathname.includes('goals')) {
        setProgress(40);
      } else if (pathname.includes('skills/guide')) {
        setProgress(60);
      } else if (pathname.includes('skills/trainee')) {
        setProgress(80);
      } else if (pathname.includes('matches/guide')) {
        setProgress(100);
      } else {
        setProgress(undefined);
      }
      return;
    }

    // Set progress for trainee/guide only profiles

    if (profile.roles.includes('trainee') && !profile.roles.includes('guide')) {
      // Trainee
      if (pathname.includes('goals')) {
        setProgress(50);
      } else if (pathname.includes('skills/trainee')) {
        setProgress(75);
      } else if (pathname.includes('matches/guide')) {
        setProgress(100);
      } else {
        setProgress(undefined);
      }
      return;
    } else {
      if (pathname.includes('goals')) {
        setProgress(50);
      } else if (pathname.includes('skills/guide')) {
        setProgress(75);
      } else {
        setProgress(undefined);
      }
      return;
    }
  }, [profile, pathname]);

  return (
    <>
      <ThemeProvider theme={{ ...theme.appTheme, palette }}>
        <ScrollToTop />
        <Header
          bgColor={'#F9FAFB'}
          onBackButtonClick={onBackButtonClick}
          logo={
            <Avatar
              src={buildSanityImageUrl({
                source: organization?.white_label?.auth0_logo ?? '',
              })}
              sx={{
                maxHeight: '130px',
                maxWidth: '130px',
                border: 'none',
              }}
            />
          }
          endAdornment={
            <Stack direction={'row'}>
              {endAdornment}
              <LanguageMenu
                arrowColor={'black'}
                localeCode={localeCode}
                languageOptions={organization?.organizationLanguages ?? []}
                ariaLabel={'Change Language Dropdown'}
                onClickChange={handleLanguageChange}
              />
            </Stack>
          }
          progress={percentage ?? progress}
        />
        <Box
          sx={{
            width: '100%',
            flex: '1 1 0',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#F9FAFB',
            pt: { xs: 0, md: 2 },
            pb: 10,
          }}
        >
          {children}
        </Box>
      </ThemeProvider>
    </>
  );
}
